import React, {useContext, useState, useEffect, useRef} from "react";
import { UserContext } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from '@mui/material/Tooltip';

import "./roomBox.scss";

const RoomBox = (props) =>{

    const [selectedRoom, setSelectedRoom] = useState([]);

    const {userId} = React.useContext(UserContext);

    return (
        <div>
            {Object.keys(props.onlineTutors).length === 0 &&(
                <div className="text">There are no tutors online right now...</div>
            )}

            {Object.keys(props.onlineTutors).length !==0 && (
                <div>
                    {Object.keys(props.onlineTutors)
                    .filter((tutors) => {
                        return tutors !== userId;
                    })
                    .map((tutor, index) => {
                        return (
                            // <div className="display-rooms">
                            //     {props.meetingId === undefined &&
                            //         <div className="rooms">
                            //             Room {index+1}
                            //             <br/>
                            //             <b>T:</b>{tutors[1]} {tutors[2]}
                            //             <br/>
                                        
                            //             <b>P:</b>
                            //         </div>
                            //     }
                            //     {props.meetingId !== undefined &&
                            //         <button className="rooms" onClick={() => {props.assignPT(props.meetingId, tutors[0]), props.setMeetingId(undefined)}}>
                            //             Room {index+1}
                            //             <br/>
                            //             <b>T:</b>{tutors[1]} {tutors[2]}
                            //             <br/>
                                        
                            //             <b>P:</b>
                            //         </button>
                            //     }
                            // </div>
                            <div key={tutor}>
                                {props.meetingId === undefined &&
                                    <div style={{border: "1px solid black", borderRadius:"4px"}}>
                                        <Tooltip className="small-screen-bt" title="Join a meeting to assign a patron">
                                            {/* MAKE THIS BUTTON GRAYED-OUT */}
                                        <button className="bad-button">Assign to Room {index+1} </button>
                                        </Tooltip>
                                        | T: {props.onlineTutors[tutor][0][1]} {props.onlineTutors[tutor][0][2]}
                                    </div>
                                }
                                {props.meetingId !== undefined &&
                                    <div style={{border: "1px solid black", borderRadius:"4px"}}>
                                        <button className="good-button" onClick={() => {props.assignPT(props.meetingId, props.onlineTutors[tutor][0][0]), props.setMeetingId(undefined)}}>Assign to Room {index+1}</button>
                                        | T: {props.onlineTutors[tutor][0][1]} {props.onlineTutors[tutor][0][2]}
                                    </div>
                                }
                            </div>
                        )
                    })
                    }
                </div>
            )}

            
        </div>
    );
}

export default RoomBox;