import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "./roomBox.scss";

async function updateDeviceNote(id, note) {
    const fullAddr = "https://illuminated.cs.mtu.edu/ark/tut/update_device_note";
	const authorization = 'Bearer ' + sessionStorage.getItem("BLIGHT");
    let response = await fetch(fullAddr, {
		method: 'POST',
		headers: {
			'Authorization': authorization,
			'Content-type': 'application/json',
		},
        body: JSON.stringify({
            "id": id,
            "notes": note,
        },)
	})
	.then(response => {
		if (response.ok) {
			return response.json()
		}
		throw response;
	})
	.catch(error => {
		console.error("error code found in (Patron.js -> joinMeeting()\n", error);
        return "error";
	})
	.finally(() => {
		//
	})

	return response;
}

const ServiceBox = (props) =>
{
    const [selectedDevice, setSelectedDevice] = useState();
    const [notes, setNotes] = useState("");
    //console.log(props.devices);
    const handleNoteChange = (e) => {
        const value = e.target.value;
        setNotes(value);
    };

    const updateNote = () => {
        let curDevice = [... selectedDevice]
        let curDevices = [... props.devices]

        curDevice[3] = notes;
        setSelectedDevice(curDevice);

        curDevices = curDevices.map((d) => d[0] === curDevice[0] ? curDevice : d);
        props.setDevices(curDevices);

        updateDeviceNote(curDevice[0], notes);
        setSelectedDevice();
        setNotes("");

    }

    return (
        <div id="service-box">
            {props.devices.length === 0 && (
                <>
                    There are no devices checked out :)
                </>
            )}

            {props.devices.length !== 0 && (
                props.devices.map(d => {
                    return(
                        <div className="device-info" key={d[1]}>
                            {d[4] === 1 && (<FontAwesomeIcon icon="fa-solid fa-circle" style={{color: "#4e9a06",}} /> )}
                            {d[4] === 0 && (<FontAwesomeIcon icon="fa-regular fa-circle" style={{color: "#a40000",}} />)}
                            
                            {d[1]} 

                            {d[3] === "" && (<FontAwesomeIcon icon="fa-regular fa-clipboard" onClick={() => {setSelectedDevice(d); setNotes(d[3]);}} />)}
                            {d[3] !== "" && (<FontAwesomeIcon icon="fa-solid fa-clipboard" onClick={() => {setSelectedDevice(d); setNotes(d[3]);}} />)}
                            
                        </div>
                    )
                })
            )}

            {selectedDevice && (
                <>
                    <textarea
                        className="notes-textbox"
                        value={notes}
                        placeholder="No notes ..."
                        onChange={handleNoteChange}
                    />
                    <button onClick={updateNote}>Update notes</button> <button onClick={() => {setSelectedDevice(); setNotes("");}}> Close note </button>
                </>
            )}

            {!selectedDevice && (
                <>
                    No device notes selected ...
                </>
            )}

        </div>
    );
}

export default ServiceBox;
